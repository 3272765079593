import React from "react";

import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";

const SwipeElement = (props) => {
    return (
        <div className="slideWrapper sm:flex items-center">
            <div className="left w-full sm:w-1/2 z-10">
                <img className="translate-x-6 " src={props.image} alt="" />
            </div>
            <div className="right sm:w-2/4 2xl:w-2/4 sm:shadow-lg ">
                <div className="rightWrapper bg-opaque sm:backdrop-blur-md min-h-20 py-5 px-5 relative ">
                    <div className="borderWrapper border-opaqueWhite sm:border-2 sm:py-36 sm:pr-10 sm:pl-20 xl:pl-20 text-white md:pl-12 pl-10 ">
                        <h2 className="font-bold text-3xl mb-2 font-slab ">{props.title}</h2>

                        <p>{props.description}</p>
                        <span className="mt-8 block font-bold ">{props.price}</span>
                        <div className="arrowWrapper absolute bottom-12 sm:flex right-12 hidden sm:text-4xl">
                            <div
                                onClick={props.backClick}
                                className="leftArrow cursor-pointer mr-5 transform transition-all hover:scale-125"
                            >
                                <IoIosArrowDropleftCircle></IoIosArrowDropleftCircle>
                            </div>
                            <div
                                onClick={props.forwardClick}
                                className="rightArrow cursor-pointer transform transition-all hover:scale-125"
                            >
                                <IoIosArrowDroprightCircle></IoIosArrowDroprightCircle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SwipeElement;
