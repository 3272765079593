import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

const BlockContent = require("@sanity/block-content-to-react");

const About = () => {
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type in ["about"]]
              `
            )
            .then((data) => {
                setPostData(data);
                console.log(data[0].text);
            })
            .catch(console.error);
    }, []);

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <>
            <div className="mb-30 sm:mb-60 p-10 container mx-auto" id="kontakt">
                <div className="sm:grid grid-cols-12 gap-4">
                    <div className="col-span-6 mb-5">{postData && <img src={urlFor(postData[0].image1)} alt="" />}</div>
                    <div className="col-span-6">
                        {postData && <BlockContent blocks={postData[0].text}></BlockContent>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
