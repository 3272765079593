import React from "react";

const Button = (props, ref) => {
    return (
        <a
            href={props.link}
            ref={props.ref}
            onClick={props.onClick}
            type={props.type}
            className={`bg-primaryRed text-white py-5 px-10 rounded block hover:bg-brightRed font-semibold cursor-pointer ${props.klasse}`}
        >
            {props.children}
        </a>
    );
};

export default Button;
