import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/sites/home";
import Party from "./components/sites/party";
import Impressum from "./components/sites/impressum";

import PagiTest from "./components/elements/pagiTest";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route element={<Home />} exact path="/"></Route>
                <Route element={<Party />} path="/partyservice"></Route>
                <Route element={<Impressum />} path="/impressum"></Route>
            </Routes>
            {/* <PagiTest fetcher="schweinefleisch"></PagiTest> */}
        </div>
    );
}

export default App;
