import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

import NumberInput from "./numberInput";
import TextInput from "./textInput";
import CalenderInput from "./calenderInput";
import RadioInput from "./radioInput";
import TelInput from "./telInput";

import Loader from "../components/elements/loader";

import Button from "../components/elements/button";
import InfoBox from "../components/elements/infoBox";

import emailjs from "@emailjs/browser";
import { serialize } from "object-to-formdata";

import ReCAPTCHA from "react-google-recaptcha";

import { useForm } from "react-hook-form";

const PartyForm = (props) => {
    const [postData, setPostData] = useState(null);
    const [warmeSpeisen, setWarmeSpeisen] = useState(null);
    const [beilagen, setBeilagen] = useState(null);
    const [vegetarisch, setVegetarisch] = useState(null);
    const [salat, setSalat] = useState(null);
    const [formData, setFormData] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [tempPrice, setTempPrice] = useState(0);
    const [abholung, setAbholung] = useState(null);

    const [captcha, setCaptcha] = useState(false);
    const [loading, setLoading] = useState(false);

    const nameRef = useRef();
    const emailRef = useRef();
    const strasseRef = useRef();
    const ortRef = useRef();
    const phoneRef = useRef();

    const [nameVal, setNameVal] = useState(false);
    const [emailVal, setEmailVal] = useState(false);
    const [strasseVal, setStrasseVal] = useState(false);
    const [ortVal, setOrtVal] = useState(false);
    const [phoneVal, setPhoneVal] = useState(false);

    function validator(input, state, field) {
        input > 0 ? state(true) : state(false);
        input === 0 ? field.classList.add("error") : field.classList.remove("error");
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const form = useRef();
    const numberForm = useRef();

    useEffect(() => {
        sanityClient
            .fetch(`*[_type in ["kalteSpeise"]] | order(order asc)`)
            .then((data) => {
                setPostData(data.sort((a, b) => b - a));
                console.log(data);
            })
            .catch(console.error);
        sanityClient
            .fetch(`*[_type in ["warmeSpeise"]] | order(order desc)`)
            .then((data) => {
                setWarmeSpeisen(data);
                console.log(data);
            })
            .catch(console.error);
        sanityClient
            .fetch(`*[_type in ["beilage"]]`)
            .then((data) => {
                setBeilagen(data);
                console.log(data);
            })
            .catch(console.error);
        sanityClient
            .fetch(`*[_type in ["vegetarisch"]]`)
            .then((data) => {
                setVegetarisch(data);
                console.log(data);
            })
            .catch(console.error);
        sanityClient
            .fetch(`*[_type in ["salat"]]`)
            .then((data) => {
                setSalat(data);
                console.log(data);
            })
            .catch(console.error);

        setTimeout(() => {
            console.log(Array.from(document.querySelectorAll(".input")));
            Array.from(document.querySelectorAll(".input")).map((e, i) => {
                if (i % 2 === 0) {
                    e.classList.add("bg-gray-100");
                }
            });
        }, 200);

        return () => {};
    }, []);

    const builder = imageUrlBuilder(sanityClient);

    function deUmlaut(value) {
        value = value.toLowerCase();
        value = value.replace(/ä/g, "ae");
        value = value.replace(/ö/g, "oe");
        value = value.replace(/ü/g, "ue");
        value = value.replace(/ß/g, "ss");
        value = value.replace(/ /g, "-");
        value = value.replace(/\./g, "");
        value = value.replace(/,/g, "");
        value = value.replace(/\(/g, "");
        value = value.replace(/\)/g, "");
        return value;
    }

    function sendEmail(e) {
        e.preventDefault();
        validator(nameRef.current.value.length, setNameVal, nameRef.current);
        validator(emailRef.current.value.length, setEmailVal, emailRef.current);
        validator(strasseRef.current.value.length, setStrasseVal, strasseRef.current);
        validator(ortRef.current.value.length, setOrtVal, ortRef.current);
        validator(phoneRef.current.value.length, setPhoneVal, phoneRef.current);
        let numberArr = Array.from(document.querySelectorAll(".numberForm"));
        numberArr.forEach((e) => {
            e.name = deUmlaut(e.name);
        });
        if (captcha && nameVal && emailVal && strasseVal && ortVal && phoneVal) {
            setLoading(true);
            emailjs.sendForm("service_cxq9cqw", "template_a5y2lmk", form.current, "user_qyuBoRWCXz73OAVVIUksj").then(
                (result) => {
                    setLoading(false);
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        } else {
            console.log("Nein");
        }
    }

    function urlFor(source) {
        return builder.image(source);
    }

    function showMe(e) {
        e.preventDefault();
        console.log(formData);
    }

    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptcha(true);
    }

    function handleChange(e) {
        // console.log(formData);
        // console.log(totalPrice);
        // console.log(parseFloat(e.target.dataset.price));
        // console.log(e.target.dataset.price);
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        setTempPrice(parseFloat(e.target.dataset.price) * e.target.value);
        console.log(tempPrice);

        // setTotalPrice((prev) => prev + parseFloat(e.target.dataset.price));
    }

    // function handleSubmit(e){
    //     console.log(e)
    // }

    function abholungCheck(e) {
        console.log(e.target.value);
        setAbholung(e.target.value);
    }

    function handleBlur(e) {
        console.log(tempPrice);
        setTotalPrice((prev) => prev + tempPrice);
        // tempPrice = 0;
        setTempPrice(0);
    }

    function validation(e) {
        console.log(e);
    }

    return (
        <div className="partyForm w-full col-span-12 mt-6">
            <form onSubmit={sendEmail} ref={form}>
                {/* <form onSubmit={handleSubmit((e) => console.log(e))} ref={form}> */}
                <div className="inputRow mb-10 p-2 sm:p-0">
                    <h2 className="mb-5 font-bold text-4xl font-slab text-primaryRed">Kalte Speisen</h2>{" "}
                    <div className="border p-6">
                        {postData &&
                            postData.map((e) => (
                                <NumberInput
                                    name={e.title
                                        .split(" ")
                                        .map((e) => e.toLowerCase())
                                        .join("")}
                                    title={e.title}
                                    price={e.price}
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e) => handleBlur(e)}
                                    ref={numberForm}
                                ></NumberInput>
                            ))}
                    </div>
                </div>
                <div className="inputRow mb-10 p-2 sm:p-0">
                    <h2 className="mb-5 font-bold text-4xl font-slab text-primaryRed">Warme Speisen</h2>
                    <div className="border p-6">
                        {warmeSpeisen &&
                            warmeSpeisen.map((e) => (
                                <NumberInput
                                    name={e.title
                                        .split(" ")
                                        .map((e) => e.toLowerCase())
                                        .join("")}
                                    title={e.title}
                                    price={e.price}
                                    onChange={(e) => handleChange(e)}
                                ></NumberInput>
                            ))}
                    </div>
                </div>
                <div className="inputRow mb-10 p-2 sm:p-0">
                    <h2 className="mb-5  font-bold text-4xl font-slab text-primaryRed">Beilagen</h2>
                    <div className="border p-6">
                        {beilagen &&
                            beilagen.map((e) => (
                                <NumberInput
                                    name={e.title
                                        .split(" ")
                                        .map((e) => e.toLowerCase())
                                        .join("")}
                                    title={e.title}
                                    price={e.price}
                                    onChange={(e) => handleChange(e)}
                                ></NumberInput>
                            ))}
                    </div>
                </div>
                <div className="inputRow mb-10 p-2 sm:p-0">
                    <h2 className="mb-5 font-bold text-4xl font-slab text-primaryRed">Vegetarisch</h2>
                    <div className="border p-6">
                        {vegetarisch &&
                            vegetarisch.map((e) => (
                                <NumberInput
                                    name={e.title
                                        .split(" ")
                                        .map((e) => e.toLowerCase())
                                        .join("")}
                                    title={e.title}
                                    price={e.price}
                                    onChange={(e) => handleChange(e)}
                                ></NumberInput>
                            ))}
                    </div>
                </div>
                <div className="inputRow mb-10 p-2 sm:p-0">
                    <h2 className="mb-5 font-bold text-4xl font-slab text-primaryRed">Salat / Desserts</h2>
                    <div className="border p-6">
                        {salat &&
                            salat.map((e) => (
                                <NumberInput
                                    name={e.title
                                        .split(" ")
                                        .map((e) => e.toLowerCase())
                                        .join("")}
                                    title={e.title}
                                    price={e.price}
                                    onChange={(e) => handleChange(e)}
                                ></NumberInput>
                            ))}
                    </div>
                </div>
                <div className="inputRow mb-10 p-2 sm:p-0">
                    <h2 className="mb-5 font-bold text-4xl font-slab text-primaryRed">Ihre Daten</h2>{" "}
                    <div className="border w-full block grid grid-cols-12 p-6 flex gap-4 items-center text-sm">
                        {/* <TextInput
                            WrapperKlasse="col-span-12 sm:col-span-6"
                            labelKlasse=""
                            inputKlasse="w-full"
                            name="name"
                            id="name"
                            placeholder=""
                            onChange={(e) => handleChange(e)}
                        >
                            Name
                        </TextInput> */}
                        <div className="col-span-12 sm:col-span-6">
                            <label className={`col-span-12 sm:col-span-6 font-semibold`} for="name">
                                Name
                            </label>
                            {/* <InputNumber defaultValue={0} controls={true}></InputNumber> */}
                            <div className={`w-full`}>
                                <input
                                    className="border w-full p-2"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    id="name"
                                    ref={nameRef}
                                    name="name"
                                    // {...register("name", { required: true })}
                                ></input>
                            </div>
                        </div>
                        {/* <TextInput
                            WrapperKlasse="col-span-12 sm:col-span-6"
                            labelKlasse=""
                            inputKlasse="w-full "
                            {...register("email")}
                            id="email"
                            placeholder=""
                            onChange={(e) => handleChange(e)}
                        >
                            Email
                        </TextInput> */}
                        <div className="col-span-12 sm:col-span-6">
                            <label className={`col-span-12 sm:col-span-6 font-semibold`} for="email">
                                Email
                            </label>
                            {/* <InputNumber defaultValue={0} controls={true}></InputNumber> */}
                            <div className={`w-full`}>
                                <input
                                    className="border w-full p-2"
                                    onChange={(e) => handleChange(e)}
                                    type="email"
                                    id="email"
                                    ref={emailRef}
                                    name="email"
                                ></input>
                            </div>
                        </div>
                        {/* <TextInput
                            WrapperKlasse="col-span-12 sm:col-span-6"
                            labelKlasse=""
                            inputKlasse="w-full "
                            {...register("strasse")}
                            id="strasse"
                            placeholder=""
                            onChange={(e) => handleChange(e)}
                        >
                            Strasse / Hausnummer
                        </TextInput> */}
                        <div className="col-span-12 sm:col-span-6">
                            <label className={`col-span-12 sm:col-span-6 font-semibold`} for="email">
                                Strasse / Hausnummer
                            </label>
                            {/* <InputNumber defaultValue={0} controls={true}></InputNumber> */}
                            <div className={`w-full`}>
                                <input
                                    className="border w-full p-2"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    id="strasse"
                                    ref={strasseRef}
                                    name="strasse"
                                ></input>
                            </div>
                        </div>
                        {/* <TextInput
                            WrapperKlasse="col-span-12 sm:col-span-6"
                            labelKlasse=""
                            inputKlasse="w-full "
                            {...register("ort")}
                            id="ort"
                            placeholder=""
                        >
                            PLZ / Ort
                        </TextInput> */}
                        <div className="col-span-12 sm:col-span-6">
                            <label className={`col-span-12 sm:col-span-6 font-semibold`} for="ort">
                                PLZ / Ort
                            </label>
                            {/* <InputNumber defaultValue={0} controls={true}></InputNumber> */}
                            <div className={`w-full`}>
                                <input
                                    className="border w-full p-2"
                                    onChange={(e) => handleChange(e)}
                                    type="ort"
                                    id="ort"
                                    ref={ortRef}
                                    name="ort"
                                ></input>
                            </div>
                        </div>
                        {/* <TelInput
                            WrapperKlasse="col-span-12 sm:col-span-6"
                            labelKlasse=""
                            inputKlasse="w-full "
                            {...register("telefon")}
                            id="telefon"
                            placeholder=""
                            onChange={(e) => handleChange(e)}
                        >
                            Telefonnummer
                        </TelInput> */}
                        <div className="col-span-12 sm:col-span-6">
                            <label className={`col-span-12 sm:col-span-6 font-semibold`} for="telefon">
                                Telefon
                            </label>
                            {/* <InputNumber defaultValue={0} controls={true}></InputNumber> */}
                            <div className={`w-full`}>
                                <input
                                    className="border w-full p-2"
                                    onChange={(e) => handleChange(e)}
                                    type="tel"
                                    id="telefon"
                                    ref={phoneRef}
                                    name="telefon"
                                ></input>
                            </div>
                        </div>
                        <CalenderInput
                            WrapperKlasse="col-span-12 sm:col-span-6"
                            labelKlasse=""
                            inputKlasse="w-full "
                            name="bestelldatum"
                            id="bestelldatum"
                            placeholder=""
                            onChange={(e) => handleChange(e)}
                        >
                            Bestelldatum
                        </CalenderInput>
                        <div className="col-span-6 sm:col-span-3 flex items-center p-2 mt-4">
                            <RadioInput
                                WrapperKlasse="col-span-12 sm:col-span-3"
                                labelKlasse="mr-5"
                                inputKlasse="w-full "
                                name="abholung"
                                id="abholung"
                                for="abholung"
                                placeholder=""
                                label="Abholung"
                                value="abholung"
                                onChange={(e) => abholungCheck(e)}
                            ></RadioInput>
                        </div>
                        <div className="col-span-6 sm:col-span-3 flex items-center p-2 mt-4">
                            <RadioInput
                                WrapperKlasse="col-span-12 sm:col-span-3"
                                labelKlasse=""
                                inputKlasse="w-full "
                                name="lieferung"
                                id="lieferung"
                                for="lieferung"
                                placeholder=""
                                label="Lieferung"
                                value="lieferung"
                                onChange={(e) => abholungCheck(e)}
                            ></RadioInput>
                        </div>
                        {abholung === "abholung" && (
                            <div className="abholung col-span-6">
                                <CalenderInput
                                    WrapperKlasse="col-span-12 sm:col-span-6"
                                    labelKlasse=""
                                    inputKlasse="w-full "
                                    name="abholdatum"
                                    id="abholdatum"
                                    placeholder=""
                                    onChange={(e) => handleChange(e)}
                                >
                                    Abholdatum
                                </CalenderInput>
                            </div>
                        )}
                        {abholung === "lieferung" && (
                            <div className="lieferung col-span-6">
                                <CalenderInput
                                    WrapperKlasse="col-span-12 sm:col-span-6"
                                    labelKlasse=""
                                    inputKlasse="w-full "
                                    name="lieferdatum"
                                    id="lieferdatum"
                                    placeholder=""
                                    onChange={(e) => handleChange(e)}
                                >
                                    Lieferdatum
                                </CalenderInput>
                            </div>
                        )}
                    </div>
                </div>
                {/* <Button type="submit" klasse="text-center">
                    Bestellen
                </Button> */}
                <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange} />
                {loading ? (
                    <div className="w-full text-center">
                        <Loader></Loader>
                    </div>
                ) : (
                    <button
                        className="bg-primaryRed text-white w-full py-5 px-10 rounded block hover:bg-brightRed font-semibold cursor-pointer mt-3"
                        type="submit"
                    >
                        Bestellen
                    </button>
                )}

                <div className="infoWrapper mt-8">
                    <InfoBox>
                        <p>
                            An Sonntagen und Feiertagen erheben wir einen <strong> Zuschlag von 50%</strong> auf den
                            Buffetpreis!
                        </p>
                        <p>
                            Lieferungen innerhalb von Oelsnitz / Erzgebirgesind ab einem Lieferpreis ab 100,00 Euro
                            inklusive.
                        </p>
                        <p>Außerhalb von Oelsnitz erheben wir einen Lieferzuschlag von 5,00 Euro.</p>
                        <p>
                            Stammkunden (mindestens 1 Einkauf pro Woche in unserem Geschäft) erhalten 10% Rabatt auf den
                            Buffetpreis.
                        </p>
                        <p>
                            Speisen können allergene Stoffe enthalten. <br></br>
                            Eine Verzeichnis mit deklarartionspflichtigen Stoffen und Allergenen liegt zur Einsicht
                            bereit.
                        </p>
                    </InfoBox>
                </div>
                {/* <input type="text" readonly value={totalPrice.toFixed(2)} />
                <div className="test">{totalPrice}</div> */}
            </form>
        </div>
    );
};

export default PartyForm;
