import React, { useState, useEffect, useRef } from "react";

import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

import PictureTag from "../elements/pictureTag";
import Headline from "../elements/headline";
import AbsoluteCenter from "../elements/absoluteCenter";
import ScrollAnimation from "react-animate-on-scroll";

import TafelBig from "../../assets/opt/tafel_big-2.png";
import TafelSmall from "../../assets/opt/mobile_tafel_neu.png";
import Fleisch from "../../assets/fleisch.png";
import Burger from "../../assets/burger.png";

const Menu = (props) => {
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type in ["menue"]]
              `
            )
            .then((data) => {
                setPostData(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
                console.log(data);
            })
            .catch(console.error);
        console.log(AbsoluteCenter);
    }, []);

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <>
            <div className="-mt-20 mb-10 sm:mb-40 container mx-auto relative" id="mittagsmenue" style={{ height: "" }}>
                <ScrollAnimation animateIn="fadeInLeftBig" offset={200} animateOnce={true}>
                    <div className="fleisch hidden xl:block sm:absolute -top-20 -left-40 z-50">
                        <img src={Fleisch} alt="" />
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn" offset={200} animateOnce={true}>
                    <div className="burger hidden xl:block sm:absolute -right-40 -bottom-20">
                        <img src={Burger} alt="" />
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn" offset={200} animateOnce={true}>
                    <div className="z-10 absolute t top-20 sm:top-20 left-1/2 transform -translate-x-1/2">
                        <Headline klasse="underline text-white">Mittagsmenü</Headline>
                    </div>
                    <div className="menues text-center gap-10 sm:grid grid-cols-12 -mt-10 z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white w-2/4">
                        <div className="col-span-6 mb-5 sm:mb-0">
                            <h4 className="font-bold text-2xl">Montag</h4>
                            {postData && <span>{postData[0].montag}</span>}
                        </div>
                        <div className="col-span-6 mb-5 sm:mb-0">
                            <h4 className="font-bold text-2xl">Dienstag</h4>
                            {postData && <span>{postData[0].dienstag}</span>}
                        </div>
                        <div className="col-span-6 mb-5 sm:mb-0">
                            <h4 className="font-bold text-2xl">Mittwoch</h4>
                            {postData && <span>{postData[0].mittwoch}</span>}
                        </div>
                        <div className="col-span-6 mb-5 sm:mb-0">
                            <h4 className="font-bold text-2xl">Donnerstag</h4>
                            {postData && <span>{postData[0].donnerstag}</span>}
                        </div>
                        <div className="col-span-6 mb-5 sm:mb-0">
                            <h4 className="font-bold text-2xl">Freitag</h4>
                            {postData && <span>{postData[0].freitag}</span>}
                        </div>
                    </div>
                    <div className="absolute mt-10 sm:mt-0 top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white w-2/4">
                        <p>
                            Bestellung unter <span className="text-red-500">+49 37298 12792</span> oder
                            <a className="text-red-500" href="mailto:fleischereifreier@gmail.com">
                                {" "}
                                fleischereifreier@gmail.com
                            </a>
                        </p>
                        <p>Abholung im Geschäft</p>
                    </div>
                </ScrollAnimation>
                {/* <div
                    className="tafel h-full bg-contain bg-no-repeat"
                    style={{ backgroundImage: `url(${Tafel})` }}
                ></div> */}
                {/* <img src={TafelBig} alt="" /> */}
                <PictureTag
                    media="(max-width: 768px)"
                    srcSet={TafelSmall}
                    src={TafelBig}
                    imgKlasse="w-full min-h-screen sm:min-h-full"
                    alt="Tafel"
                ></PictureTag>
            </div>
        </>
    );
};

export default Menu;
