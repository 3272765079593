import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import MenuFixed from "../header/menuFixed";

import Footer from "../footer/footer";

const BlockContent = require("@sanity/block-content-to-react");

const Impressum = () => {
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type in ["party"]]`)
            .then((data) => {
                setPostData(data);
                console.log(data);
            })
            .catch(console.error);

        return () => {};
    }, []);

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <div className="impressum">
            <MenuFixed klasse="menuStuff"></MenuFixed>

            <div className="container mx-auto  relative md:pt-16 mb-32 row-auto">
                <div className="wrapper pt-20">
                    <h1>Impressum</h1>

                    <h2 className="mt-5 mb-3">Angaben gemäß § 5 TMG</h2>
                    <p>
                        Jörg Freier
                        <br />
                        Fleisch und Wurstwaren
                        <br />
                        Kammerrat Beck Strasse 8<br />
                        09376 Oelsnitz
                    </p>

                    <h2 className="mt-5 mb-3">Kontakt</h2>
                    <p>
                        Telefon: +49 (0) 37298/12792
                        <br />
                        E-Mail: fleischereifreier@gmail.com
                    </p>

                    <h2 className="mt-5 mb-3">Umsatzsteuer-ID</h2>
                    <p>
                        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                        <br />
                        224/220/02700
                    </p>

                    <h2 className="mt-5 mb-3">Berufsbezeichnung und berufsrechtliche Regelungen</h2>
                    <p>
                        Berufsbezeichnung:
                        <br />
                        Fleischer
                    </p>
                    <p>
                        Zuständige Kammer:
                        <br />
                    </p>
                    <p>
                        Verliehen in:
                        <br />
                    </p>
                    <p>Es gelten folgende berufsrechtliche Regelungen:</p>
                    <h2 className="mt-5 mb-3">Redaktionell verantwortlich</h2>
                    <p>Jörg Freier</p>

                    <h2 className="mt-5 mb-3">EU-Streitschlichtung</h2>
                    <p>
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
                        <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
                            https://ec.europa.eu/consumers/odr/
                        </a>
                        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
                    </p>

                    <h2 className="mt-5 mb-3">Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
                    <p>
                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.
                    </p>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Impressum;
