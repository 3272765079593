import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

import Bueffel from "../../assets/bueffel.png";
import BueffelSmall from "../../assets/bueffelSmall.png";
import Button from "../elements/button";
import Indicator from "../elements/indicator";

import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

import collide from "../../functions/collide";

import Sticky from "react-stickynode";

const Hero = () => {
    const [postData, setPostData] = useState(null);
    const [isUnder, setIsUnder] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const overlayRef = useRef();
    const headerRef = useRef();
    const bueffelRef = useRef();
    const buttonRef = useRef();
    const buttonRef2 = useRef();
    const socialsRef = useRef();
    const indicatorRef = useRef();
    const hoursRef = useRef();
    const wrapperRef = useRef();
    const treshold = 1600;

    const handleResize = () => {
        console.log(detectMobile());
        detectMobile() ? setIsMobile(true) : setIsMobile(false);
    };

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 400 && position < 1260) {
            overlayRef.current.style.opacity = 0.4 + position / 2 / 1100;
            // overlayRef.current.style.opacity = 0.7 - position / 2 / 1000;
        }
        // headerRef.current.style.transform = `scale(${1 - position / 2 / 200})`;
        // bueffelRef.current.style.transform = `translate( 0px,${1 - position}px)`;
        if (position > 300) {
            headerRef.current.classList.remove("slide-in-right");
            headerRef.current.classList.add("slide-out-right");
            bueffelRef.current.classList.remove("slide-in-left");
            bueffelRef.current.classList.add("slide-out-left");
            hoursRef.current.classList.remove("slide-in-right");
            hoursRef.current.classList.add("slide-out-right-delayed");
            buttonRef.current.classList.remove("fade-in");
            buttonRef.current.classList.add("fade-out");
            if (detectMobile()) {
                buttonRef2.current.classList.remove("fade-in");
                buttonRef2.current.classList.add("fade-out");
            }

            // bueffelRef.current.style.transform = `scale(${1 - position / 2 / 200})`;
        }
        if (position <= 300 && headerRef.current.classList.contains("slide-out-right")) {
            headerRef.current.classList.remove("slide-out-right");
            headerRef.current.classList.add("slide-in-right");
            bueffelRef.current.classList.remove("slide-out-left");
            bueffelRef.current.classList.add("slide-in-left");
            hoursRef.current.classList.remove("slide-out-right-delayed");
            hoursRef.current.classList.add("slide-in-right");
            buttonRef.current.classList.remove("fade-out");
            buttonRef.current.classList.add("fade-in");

            if (detectMobile()) {
                buttonRef2.current.classList.remove("fade-out");
                buttonRef2.current.classList.add("fade-in");
            }
        }

        if (position >= 800) {
            wrapperRef.current.style.left = "-9999px";
        } else if (position <= 400) {
            wrapperRef.current.style.left = "50%";
        }
        // if (indicatorRef.current.getBoundingClientRect().top + indicatorRef.current.clientHeight / 2 <= 0) {
        //     indicatorRef.current.style.position = "fixed";
        //     indicatorRef.current.style.top = 0;
        //     console.log("fixed");
        // } else {
        //     indicatorRef.current.style.position = "absolute";
        // }
    };

    function detectMobile() {
        return window.innerWidth <= 480 ? true : false;
    }

    function showUnder() {}

    useEffect(() => {
        detectMobile() ? setIsMobile(true) : setIsMobile(false);
        sanityClient
            .fetch(
                `*[_type in ["heroBG", "heroText"]]{
                    heroBGImage,
                     "video": video.asset->url,
                     heroHeadline,
                     heroOpeningHours,
                     heroOpeningHours2,
                     heroOpeningHours3,
                     heroButtonText
      
             }
              `
            )
            .then((data) => {
                setPostData(data);
            })
            .catch(console.error);
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <>
            <div className="heroContainer w-full relative h-screen sm:h-6/6">
                {" "}
                {postData && (
                    <>
                        <div
                            ref={wrapperRef}
                            className="textContainer flex justify-center align-center flex-col text-center z-10 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        >
                            <div ref={headerRef} className="headerWrapper">
                                <h1 className="font-slab text-4xl sm:text-4xl md:text-6xl lg:text-6xl xl:text-7xl 3xl:text-9xl text-white font-bold scale-in-center">
                                    {postData[1].heroHeadline}
                                </h1>
                            </div>
                            <div ref={bueffelRef} className="picWrapper">
                                <picture className="flex sm:h-full sm:w-full  justify-center mt-5 sm:mt-10 scale-in-center">
                                    <source media="(min-width: 480px)" srcSet={Bueffel} />
                                    <img src={BueffelSmall} alt="Büffel" />
                                </picture>
                            </div>
                            <div ref={hoursRef} className="hoursWrapper">
                                <div className="opening mt-5 sm:mt-10 xl:mt-15 text-white font-bold 2xl:text-2xl">
                                    Öffnungszeiten:
                                </div>
                                <div className="grid grid-cols-12 mt-3">
                                    <div className="col-span-6 text-white  4xl:text-2xl text-right pr-10">
                                        Mo. - Do.
                                    </div>
                                    <div className="hours col-span-6 text-white text-left 4xl:text-2xl">
                                        {`${postData[1].heroOpeningHours.split(" ")[0]} - ${
                                            postData[1].heroOpeningHours.split(" ")[2]
                                        }`}
                                    </div>
                                    <div className="col-span-6 text-white  4xl:text-2xl text-center pr-10"></div>
                                    <div className="hours col-span-6 text-white text-left 4xl:text-2xl">
                                        {`${postData[1].heroOpeningHours.split(" ")[3]} - ${
                                            postData[1].heroOpeningHours.split(" ")[5]
                                        }`}
                                    </div>
                                    <div className="col-span-6 text-white 4xl:text-2xl text-right pr-10">Fr.</div>
                                    <div className="hours col-span-6  text-white text-left  4xl:text-2xl">
                                        {postData[1].heroOpeningHours2}
                                    </div>
                                    <div className="col-span-6 text-white  4xl:text-2xl text-right pr-10">Sa.</div>

                                    <div className="hours col-span-6 text-white  text-left 4xl:text-2xl">
                                        {postData[1].heroOpeningHours3}
                                    </div>
                                </div>
                            </div>
                            {!isMobile && (
                                <div ref={buttonRef} className=" text-center  flex justify-center mt-6 sm:mt-10">
                                    <Button klasse="bottom-0 w-2/4" link="#angebote">
                                        Angebote
                                    </Button>
                                </div>
                            )}
                        </div>
                        {/* <video
                            id="background-video"
                            autoplay
                            loop
                            muted
                            poster="https://assets.codepen.io/6093409/river.jpg"
                        >
                            <source src={postData[0].video} type="video/mp4" />
                        </video> */}
                        <Sticky enabled={true} bottomBoundary={treshold + 1000}>
                            <div
                                onClick={showUnder}
                                ref={overlayRef}
                                className="videoOverlay h-screen w-full absolute bg-black opacity-70 z-10"
                            ></div>

                            <video
                                className="object-cover w-full h-screen absolute top-0 left-0"
                                src={postData[0].video}
                                autoPlay
                                muted
                                loop
                            ></video>
                        </Sticky>

                        {/* <div
                            className={`w-full heroImageBG h-full bg-cover relative bg-center  bg-[url('${urlFor(
                                postData[0].heroBGImage
                            )}')]`}
                            style={{ backgroundImage: "url(" + urlFor(postData[0].heroBGImage) + ")" }}
                        >
                            <div className="overlay top-0 absolute h-full opacity-70 w-full bg-black"></div>
                        </div> */}
                        {/* <div className="openening absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        Öffnungszeiten
                    </div> */}
                        {/* <div
                            ref={buttonRef}
                            className="w-full text-center sm:w-auto fixed top-3/4 left-1/2 transform -translate-x-1/2"
                        >
                            <Button link="#angebote">Unsere Angebote</Button>
                        </div> */}
                        {isMobile && (
                            <>
                                <div
                                    ref={buttonRef}
                                    className="fixed bottom-0 left-0 text-center w-2/4 flex justify-center mt-6 sm:mt-10"
                                >
                                    <Button klasse="bottom-0 w-full" link="#angebote">
                                        Angebote
                                    </Button>
                                </div>
                                <div
                                    ref={buttonRef2}
                                    className="fixed bottom-0 right-0 text-center w-2/4 flex  justify-center mt-6 sm:mt-10"
                                >
                                    <Button klasse="bottom-0 w-full" link="#mittagsmenue">
                                        Mittagsmenü
                                    </Button>
                                </div>
                            </>
                        )}

                        <div className="indicatorWrapper absolute right-4 bottom-8 " ref={indicatorRef}>
                            <Indicator klasse="bg-primaryRed w-8 h-8 rounded-full"></Indicator>
                        </div>
                        {/* <div
                            ref={socialsRef}
                            className="socials hidden sm:flex  text-white fixed text-lg  right-8 top-10 z-50"
                        >
                            <FaFacebook className="mr-4"></FaFacebook>
                            <AiFillInstagram></AiFillInstagram>
                        </div> */}
                    </>
                )}
            </div>
            {isMobile ? (
                <div className="heightMann" style={{ height: treshold - 400 }}></div>
            ) : (
                <div className="heightMann" style={{ height: treshold - 600 }}></div>
            )}
        </>
    );
};

export default Hero;
